import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const NonSurgicalHairReplacementforWomen = () => {

  const seoData = {
    title: 'Non Surgical Hair Replacement for Women in Delhi - Radiance',
    description: 'Non Surgical Hair Replacement for women in Delhi, give you the appearance you need. Radiance Hair Studio offers the best in class services throughout Delhi.',
    keywords: ['non surgical hair replacement for women'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "mainEntityOfPage": "Service Page",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Non-surgical-Hair-Replacement-for-Women's.jpg",
      "https://www.radiancehairstudio.com/non-surgical-hair-replacement1.png",
      "https://www.radiancehairstudio.com/non-surgical-hair-replacement2.png"
    ],
    "additionalType": "https://www.radiancehairstudio.com/non-surgical-hair-replacement.php",
    "category": "Hair Wigs",
    "description": "Non Surgical Hair Replacement for women in Delhi, give you the appearance you need. Radiance Hair Studio offers the best in class services throughout Delhi.",
    "disambiguatingDescription": "Non-surgical hair replacement for women",
    "serviceType": "Wig for Women"
  };
  


  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Non-surgical-Hair-Replacement-for-Women's.jpg",
      content: (
        <div>
          <h2>Non-surgical Hair Replacement for Women's</h2>
          <p>Hair fall has now become a regular problem for women living in the metros. Oral medicines are there in the market with their promises to grow your hair naturally. However, you tested all of them and by now must be already aware of their actual effectiveness. Standing at such a critical condition Non-Surgical Hair replacement supported by wigs can be a perfect solution for you.</p>
          <p>This particular hair replacement method needs specialized experts into the matter. We, Radiance Hair Studio stand perfectly by your side, to ensure the best results. Our experts are experienced into the practice and well trained with the glues and tapes to give you the most natural appearance. Reach us and see the difference we create in your look.</p>
          <h2>Non-Surgical Hair replacement – an exclusive service for Women</h2>
          <p>Unlike surgical hair replacement, <a href="/non-surgical-hair-replacement-for-men.php" style={{ color: "#373737" }}>Non-Surgical</a> Hair replacement will give you the appearance you need by simple means. So, it’s simple and a painless journey for all. What you need is the right glue, tape or clip – that’s it.</p>
          <p>Radiance Hair Studio again stands flawlessly for you with the best quality adhesive, perfect tapes or clips, and human hair – all of them at a cost, well within your budget. See and learn the benefits, you will get from us while going for this hair replacement.</p>
        </div>
      )
    }
  ];

  const sectionS3 = [
    {
      smallImg: "/non-surgical-hair-replacement1.png",
      largeImg: "/non-surgical-hair-replacement2.png",
      content: (
        <div>
          <h2>Benefits of applying Non-Surgical Hair replacement from us</h2>
          <p></p>
          <ul >
            <li>This particular hair replacement is perfectly suited for your budget. This is one reason, why many do choose this replacement procedure.</li>
            <li>The <a href="/services.php" style={{ color: "#373737" }}>extension</a> to be applied is totally an external one. Hence there remains no side-effect and no strain.</li>
            <li>The most attractive part of the entire treatment remains in the new look you will get. You can choose any cut you like, including Bobs, Neck-long cuts or even long hair.</li>
            <li>This particular replacement is long lasting and you remain free to enjoy your life with this new look, without any hassle and tension.</li>
          </ul>
          <p></p>
        </div>
      )
    }
  ];

  const sectionS4 = [
    {
      title: "Radiance’s answer to your FAQ",
      description: "With the above-said points in mind, you might be looking for some questions. Here are the answers to some of your common queries.",
      description2: "",
      faq: [
        {
          title: "Is the treatment a permanent one?",
          description: "Non-Surgical Hair replacement is not a permanent solution for your hair fall. Time to time, you will have to reach us at Radiance. Our professionals will do the least thing to give back your better look in the new style."
        },
        {
          title: "Can I stand storm or go for a swim with the wig?",
          description: "You can stand storms and even can wet the wig – there lies no problem in it. The quality of the hair we provide and the adhesive we use are of the best quality to ensure your easy life."
        },
        {
          title: "How much strain I will have to bear for the treatment?",
          description: "The treatment will cost you not a minute strain. The entire treatment is an externally applied one with no side effects. Moreover, our professionals are perfectly equipped with training to make the entire process hassle-free."
        },
        {
          title: "What Are Your Wig Shopping Tips?",
          description: "The shopping guide to your wig is all discussed above. At RADIANCE we offer a free consultation that will help you buy the best for urself."
        },
        {
          title: "What are the disadvantages of wearing a wig?",
          description: "Disadvantages! While fighting cancer! Maybe A WRONG QUESTION ASKED! Natural human hair wigs are blessings in disguise. All natural, no side effects, and longevity. What more do you want?"
        },
        {
          title: "Can I apply a different hairstyle with it?",
          description: "Yes, you can apply new hairstyle, but to get that new look, you will have to reach us at Radiance. Our experts will support you in the entire matter."
        }
      ],
    }
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Wig for Women's" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Non-surgical Hair Replacement for Women's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default NonSurgicalHairReplacementforWomen;
